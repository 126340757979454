import { Helmet } from 'react-helmet-async'
import Cover from '../../components/Cover/Cover'
import PricesAccordion from './PricesAccordion/PricesAccordion'
import './PricesPage.css'

const PricesPage = ({serviceArr}) => {
  return (
    <>
    <Helmet>
        <title>Ціни на медичні послуги в Чернігові - Filada</title>
        <meta
          name="description"
          content="Ціни на послуги в Медичному центрі ᐉ Filada ⭐ Запис до лікаря ✔️ Якісне лікування по доступній ціні. Гарантія. Тел.063-503-04-72"
        />
        <meta property="og:url" content="https://filada.com.ua/prices/" />
        <link rel="canonical" href="https://filada.com.ua/prices/" />
        <script>{`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-K92BJD9R')        
      `}</script>
      </Helmet>
    <div className='prices__page'>
         <Cover coverDescription={"Ціни"} />
         <PricesAccordion serviceArr={serviceArr}/>
    </div>
     {/* Код Google Tag Manager (noscript) */}
     <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-K92BJD9R"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          ></iframe>
      </noscript>
    </>
  )
}

export default PricesPage