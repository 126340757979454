export const ABOUTE_ROUTE = "/aboute_us/"
export const PRICES_ROUTE = "/prices/"
export const CONTACTS_ROUTE = "/contacts/"
export const SERVICE_PASTIC_ROUTE = "/plastic-surgery/"
export const SERVICE_GINECOLOGY_ROUTE = "/gynecology/"
export const SERVICE_COSMETOLOGY_ROUTE = "/cosmetology/"
export const SERVICE_DERMATOLOGY_ROUTE = "/dermatology/"
export const SERVICE_PROCTOLOGY_ROUTE = "/proctology/"
export const SERVICE_OTOLARYNGOLOGY_ROUTE = "/otolaryngology/"
export const SERVICE_NEUROLOGY_ROUTE = "/neurology/"
export const SERVICE_VESSELS_ROUTE = "/ultrasound-diagnostics/"
export const SERVICE_ENDOCRINOLOGY_ROUTE = "/endocrinology/"
export const SERVICE_DIETICS_NUTRITION_ROUTE = "/dietetics-nutrition/"
