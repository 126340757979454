import {
    SERVICE_COSMETOLOGY_ROUTE,
    SERVICE_DERMATOLOGY_ROUTE,
    SERVICE_DIETICS_NUTRITION_ROUTE,
    SERVICE_ENDOCRINOLOGY_ROUTE,
    SERVICE_GINECOLOGY_ROUTE,
    SERVICE_NEUROLOGY_ROUTE,
    SERVICE_OTOLARYNGOLOGY_ROUTE,
    SERVICE_PASTIC_ROUTE,
    SERVICE_PROCTOLOGY_ROUTE,
  } from "../utils/routes";

export const navArr = [
    {
        title: "Пластична хірургія",
        path: SERVICE_PASTIC_ROUTE,
    },
    {
        title: "Гінекологія",
        path: SERVICE_GINECOLOGY_ROUTE,
    },
    {
        title: "Косметологія",
        path: SERVICE_COSMETOLOGY_ROUTE,
    },
    {
        title: "Дерматологія",
        path: SERVICE_DERMATOLOGY_ROUTE,
    },
    {
        title: "Проктологія",
        path: SERVICE_PROCTOLOGY_ROUTE,
    },
    {
        title: "ЛОР",
        path: SERVICE_OTOLARYNGOLOGY_ROUTE,
    },
    {
        title: "Неврологія",
        path: SERVICE_NEUROLOGY_ROUTE,
    },
    {
        title: "Ендокринологія",
        path: SERVICE_ENDOCRINOLOGY_ROUTE,
    },
    {
        title: "Дієтологія/Нутриціологія",
        path: SERVICE_DIETICS_NUTRITION_ROUTE,
    },
  
]