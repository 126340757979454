import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setBurgerIsClose } from "../../redux/BurgerSlice/BurgerSlice";
import arrowBlack from "../../assets/icons/ArrowBlack.png";
import arrowGold from "../../assets/icons/ArrowGold.png";
import {
  ABOUTE_ROUTE,
  CONTACTS_ROUTE,
  PRICES_ROUTE,
} from "../../utils/routes";
import "./Burger.css";
import { navArr } from "../../utils/navArr";

const Burger = () => {
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((state) => state.theme.isDarkTheme);
  const isActive = useSelector((state) => state.burger.isBurgerActive);
  const [isSecondLevelBurgerActive, setIsSecondLevelBurgerActive] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const [isThirdLevelBurgerActive, setIsThirdLevelBurgerActive] = useState(false);
  const [activeSubCategory, setActiveSubCategory] = useState(null);

  useEffect(() => {
    const body = document.body;
    if (isActive) {
      body.style.overflow = "hidden";
    } else if (!isActive) {
      body.style.overflow = "auto";
    }

    return () => {
      body.style.overflow = "auto";
    };
  }, [isActive, isSecondLevelBurgerActive, isThirdLevelBurgerActive, activeSubCategory]);

  const handleSecondLevelMenuOpen = () => {
    setIsSecondLevelBurgerActive(true);
  };
  const handleSecondLevelMenuClose = () => {
    setIsSecondLevelBurgerActive(false);
    setActiveCategory(null);
  };

  const handleBurgerClose = () => {
    dispatch(setBurgerIsClose());
  };

  return (
    <div className={`burger__menu ${isActive ? "active" : ""}`}>
      <div className={`burger__overlay ${isDarkTheme ? "" : "light"}`}>
        <div className="burger__body">
          <div onClick={handleSecondLevelMenuOpen} className={`burger__item-catalog ${isDarkTheme ? "" : "light"} mont-r`}>
            Послуги <img src={isDarkTheme ? arrowGold : arrowBlack} alt="перейти до каталогу товарів" className="arrow__mobile" />
          </div>
          <Link onClick={handleBurgerClose} className={`burger__link ${isDarkTheme ? "" : "light"}  mont-r`} to={"/"}>
            Головна
          </Link>
          <Link onClick={handleBurgerClose} className={`burger__link ${isDarkTheme ? "" : "light"} mont-r`} to={ABOUTE_ROUTE}>
            Про нас
          </Link>
          <Link onClick={handleBurgerClose} className={`burger__link ${isDarkTheme ? "" : "light"} mont-r`} to={PRICES_ROUTE}>
           Ціни 
          </Link>
          <Link onClick={handleBurgerClose} className={`burger__link ${isDarkTheme ? "" : "light"} mont-r`} to={CONTACTS_ROUTE}>
            Контакти
          </Link>
          <a
                onClick={handleBurgerClose}
                target={"_blank"}
                rel={"noreferrer"}
                className={`burger__link ${isDarkTheme ? "" : "light"} mont-r`}

                href="https://www.google.com/maps/place/Medychnyy+Tsentr+Filada/@51.490013,31.303919,15z/data=!4m6!3m5!1s0x46d5492fe0c4212f:0x835a19a342e61be!8m2!3d51.4900131!4d31.3039185!16s%2Fg%2F11w91zpjw5?hl=ru&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
              >
                Відгуки
              </a>
        </div>

        <div className={`burger__body-second ${isDarkTheme ? "" : "light"} ${isSecondLevelBurgerActive ? "active" : ""}`}>
          <div onClick={handleSecondLevelMenuClose} className={`burger__body-second-back ${isDarkTheme ? "" : "light"} mont-r`}>
            <span>{"< Назад"}</span> <span>Послуги</span>
          </div>
          {navArr.map((category, idx) => (
            <Link
            onClick={handleBurgerClose}
            to={category.path}
              key={idx}
              className={`burger__item-catalog ${isDarkTheme ? "" : "light"} mont-r`}
            >
              {category.title} <img src={isDarkTheme ? arrowGold : arrowBlack} alt="перейти до подкатегорий" className="arrow__mobile" />
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Burger;

