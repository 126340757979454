export const textPlastic = (
  <div>
    <h2 className="arial-r-seo">Пластична хірургія в Чернігові</h2>
    <p className="seo__description">
      З кожним роком все більше чоловіків та жінок вирішують скористатися
      послугами пластичної хірургії. Це досить швидкий, доступний та водночас
      безпечний метод корекції зовнішності. Пластична хірургія допомагає
      позбутися від шкірних новоутворень, прибрати наслідки травм та оперативних
      втручань, запобігти виникненню вікових змін та продовжити молодість. Наш
      сучасний медичний центр Filada має у своєму арсеналі значну кількість
      високоефективних та безпечних методів, кваліфікованих спеціалістів —
      майстрів своєї справи, та сучасне діагностичне й хірургічне устаткування.
      Ми працюємо на совість та на результат, і допомагаємо абсолютно без болю
      та стресу досягти гармонійних пропорцій тіла, змоделювати ідеальні контури
      обличчя, зменшити або збільшити об`єми тканин тощо. Тому якщо вас
      цікавить пластичний хірург в Чернігові, звертайтеся до Filada.
    </p>
    <h3 className="seo__subtitle mont-m">
      Напрямки пластичної хірургії в Filada
    </h3>
    <p className="seo__description">
      Сучасна пластична хірургія дозволяє розв`язати широкий спектр завдань,
      пов`язаних із усуненням вроджених патологій, оперативних втручань,
      вікових змін, нещасних випадків тощо. Наші експерти працюють на стику
      наук, поєднуючи досягнення мікрохірургії, дерматології, щелепно-лицьової
      хірургії тощо.
    </p>
    <p className="seo__description">
      Серед найпоширеніших втручань, які проводять експерти клініки Filada:
    </p>
    <p className="seo__list-item">
      Мамопластика. Ця пластична операція дозволяє збільшити або зменшити
      об`єм грудей, а також скоригувати форму молочних залоз, забезпечити
      підтяжку, усунути асиметрію, змінити вигляд сосків та ареолів.
      Кваліфіковані хірурги можуть повністю відновити як форму, так і об`єм
      грудей.
    </p>
    <p className="seo__list-item">
      Корекція обличчя. Це досить широкий спектр процедур, що можуть бути
      спрямовані як на боротьбу з віковими змінами, так і на корекцію певних рис
      чи дефектів. До них входять маніпуляції, які допомагають змінити овал
      обличчя, положення та форму вух, скоригувати вилиці, змінити форму
      підборіддя, форму повік або розріз очей. І це лише кілька прикладів
      маніпуляцій, які можуть виконувати наші експерти.
    </p>
    <p className="seo__list-item">
      Ринопластика. Це різноманітні оперативні втручання, що допомагають змінити
      або виправити форму носової перегородки, прибрати наслідки хронічних
      запальних процесів або травм.
    </p>
    <p className="seo__list-item">
      Естетична хірургія. Дозволяє прибрати рубці та шрами, наслідки оперативних
      втручань тощо. Також методи естетичної хірургії дозволяють позбутися від
      значних вікових змін, повернути шкірі молодість та тургор. І вони не
      передбачають класичного хірургічного втручання.
    </p>
    <p className="seo__list-item">
      Реконструктивна пластика. Направлена на відновлення форми та здорової
      функціональності певних ділянок тіла шляхом заміщення пошкоджених тканин.
    </p>
    <p className="seo__list-item">
      Ліпосакція. Є популярним та водночас ефективним хірургічним втручанням,
      яке дозволяє видалити надмірні підшкірні жирові відкладення на різних
      ділянках тіла, зокрема животі, стегнах тощо. Паралельно з таким втручанням
      може проводитися видалення тканин, що були деформовані через розтягнення.
    </p>
    <p className="seo__list-item">
      Видалення доброякісних новоутворень. Це можуть бути родимки та папіломи,
      ліпоми й атероми, гемангіоми та ін.
    </p>
    <p className="seo__description">
      Пластична хірургія в Чернігові у нашій клініці передбачає виключно
      індивідуальний підхід до кожного окремого випадку. Це дозволяє гарантовано
      досягти потрібного результату та тішитися своїм відображенням у дзеркалі.
      А дізнатися більше, які операції проводять пластичні хірурги в клініці
      “Filada” можна на нашому сайті або по вказаним на ньому номерам телефонів.
    </p>

    <h3 className="seo__subtitle mont-m">
      Кому варто отримати консультацію пластичного хірурга?
    </h3>
    <p className="seo__description">
      Записатися на прийом до пластичного хірурга можна у різних ситуаціях.
      Найчастіше до такого лікаря звертаються дві групи людей — ті, хто мають
      певні медичні показання до втручання, та охочі до естетичних змін.
    </p>
    <p className="seo__description">До медичних показань відносять:</p>
    <p className="seo__list-item">вроджені дефекти та пороки;</p>
    <p className="seo__list-item">
      наслідки перенесених хвороб чи нещасних випадків;
    </p>
    <p className="seo__list-item">наслідки оперативних втручань.</p>
    <p className="seo__description">
      Що стосується естетичних причин, то вони включають природне бажання
      протистояти віковим змінам, а також бажання усунути певні косметичні
      недоліки зовнішності.
    </p>
    <p className="seo__description">
      Консультація пластичного хірурга допомагає зрозуміти, чого очікувати від
      втручання, а також отримати всю інформацію про особливості операції,
      терміни відновлення й можливі ризики. Лікарю можна поставити всі питання,
      що вас турбують та отримати ретельні відповіді.
    </p>
    <h3 className="seo__subtitle mont-m">Чому варто обрати клініку Filada?</h3>
    <p className="seo__description">
      Клініка Filada — це сучасний медичний центр у Чернігові. В нас працюють
      виключно висококваліфіковані спеціалісти, що мають великий практичний
      досвід. Наші експерти можуть проводити пластичні операції різного ступеня
      важкості. В нас можна:
    </p>
    <p className="seo__list-item">отримати комплексну консультацію лікаря;</p>
    <p className="seo__list-item">
      відчути всі переваги пластичної хірургії у сучасному світі;
    </p>
    <p className="seo__list-item">пройти необхідні обстеження;</p>
    <p className="seo__list-item">
      отримати якісну допомогу у розв`язанні ваших проблем;
    </p>
    <p className="seo__list-item">забути про стреси та невпевненість у собі.</p>
    <p className="seo__description">
      Наші експерти є справжніми професіоналами. Вони регулярно проходять
      стажування для підвищення рівня своєї кваліфікації та освоюють нові
      методики естетичної хірургії, що тільки з`являються і світі. У клініці
      Filada віддають перевагу сучасним малоінвазивним методикам, адже це
      ефективна пластична хірургія, що дозволяє значно скоротити терміни
      реабілітації. До того ж значну кількість маніпуляцій ми виконуємо протягом
      кількох годин — і вони не потребують госпіталізації клієнта. А у разі
      складних оперативних втручань пацієнт обов`язково залишається під
      контролем лікаря.
    </p>
    <p className="seo__description">
      Всі співробітники клініки Filada є дуже емпатичними та з особливою
      педантичністю ставляться до кожного пацієнта. Ми робимо все, що тільки від
      нас залежить для підвищення вашого комфорту. Запис на консультацію до
      пластичного хірурга здійснити дуже просто — впишіть свій номер у форму на
      нашому сайті або напишіть нам в дірект, і ми обов`язково
      зв`яжемося з вами у зручний для вас час.
    </p>
  </div>
);

export const textGinecology = (
  <div>
    <h2 className="arial-r-seo">Гінекологія в Чернігові</h2>
    <p className="seo__description">
      Багато жіночих хвороб можуть протягом тривалого часу мати прихований та
      абсолютно безсимптомний характер. Саме тому кожна жінка має хоча б раз на
      рік відвідувати лікаря-гінеколога. Це самий головний спеціаліст для кожної
      дівчини та жінки. Він контролює жіноче здоров`я, допомагає своєчасно
      виявити, діагностувати та вилікувати різні проблеми. А якщо вам потрібен
      кваліфікований гінеколог в Чернігові, клініка Filada запрошує вас на
      прийом. Ми турбуємося про наших пацієнтів та робимо все необхідне, аби
      обстеження було комфортним.
    </p>
    <h3 className="seo__subtitle mont-m">
      Навіщо потрібен регулярний скринінг?
    </h3>
    <p className="seo__description">
      Сама основна причина для того, щоб відвідувати гінеколога хоча б раз на
      рік — це можливість своєчасної діагностики небезпечної хвороби — раку
      шийки матки. Це вкрай поширене онкологічне захворювання. Воно знаходиться
      на 6 місці по поширеності серед всіх видів раку, що зустрічається у жінок.
      І рак шийки матки добре піддається лікуванню, якщо ця хвороба була
      діагностована своєчасно. Виявити її допоможе огляд гінеколога.
    </p>
    <p className="seo__description">
      Але рак — це не єдина причина для регулярного скринінгу. Є й інші хвороби,
      що можуть протікати малопомітно та потребувати своєчасного лікування.
      Серед них:
    </p>
    <p className="seo__list-item">
      доброякісні новоутворення — пухлини та кісти;
    </p>
    <p className="seo__list-item">папіломи та поліпи різної локалізації;</p>
    <p className="seo__list-item">
      чисельні інфекції, що передаються статевим шляхом;
    </p>
    <p className="seo__list-item">
      порушення у роботі м`язів тазового дна тощо.
    </p>

    <p className="seo__description">
      Вартість прийому гінеколога в Чернігові є досить демократичною. І
      ігнорувати регулярний скринінг не варто. Своєчасне виявлення та лікування
      жіночих проблем допомагає уникнути тяжких наслідків.
    </p>

    <h3 className="seo__subtitle mont-m">
      Коли жінці може знадобитися позаплановий візит?
    </h3>
    <p className="seo__description">
      Окрім регулярного скринінгу, що є відмінним способом профілактики жіночих
      хвороб, є й деякі стани, що потребують позапланової консультації. Але коли
      звертатись до гінеколога? Взагалі, відвідати лікаря поза звичним графіком
      потрібно:
    </p>
    <p className="seo__list-item">дівчаткам, у яких почалися менструації; </p>
    <p className="seo__list-item">після втрати незайманості;</p>
    <p className="seo__list-item">при підготовці до вагітності;</p>
    <p className="seo__list-item">після пологів; </p>
    <p className="seo__list-item">при появі перших симптомів клімаксу. </p>
    <p className="seo__description">
      Також позаплановий візит може знадобитися при появі будь-яких проявів
      нездужання. Розглянемо основні симптоми при гінекологічних захворюваннях
      більш детально. До них належать:
    </p>
    <p className="seo__list-item">
      порушення менструального циклу (болючість, зміна тривалості, дуже рясні
      або навпаки невеликі виділення);
    </p>
    <p className="seo__list-item">біль під час статевого акту;</p>
    <p className="seo__list-item">дискомфорт та біль при сечовипусканні;</p>
    <p className="seo__list-item">
      свербіж, почервоніння та висипання на поверхні статевих органів та на
      шкірі навколо;
    </p>
    <p className="seo__list-item">
      незвичайні виділення з піхви, що відрізняються від звичних за
      консистенцією, кольором, запахом тощо.
    </p>
    <p className="seo__description">
      Якщо ви виявили у себе хоч один з описаних симптомів, здійсніть запис до
      гінеколога найближчим часом. Адже чим раніше буде виявлена хвороба, тим
      легше з нею буде впоратися. Також рання діагностика та своєчасне лікування
      знижують ризик ускладнень.
    </p>
    <h3 className="seo__subtitle mont-m">
      Як підготуватися до візиту до гінеколога?
    </h3>
    <p className="seo__description">
      Якщо у вас запланована консультація гінеколога, варто запитати попередньо
      про необхідність особливої підготовки. Зокрема деякі різновиди
      діагностичних маніпуляцій краще виконувати у певні дні менструального
      циклу. Зокрема УЗД грудей краще робити у перші 5-10 днів циклу. Загальні
      правила підготовки до візиту включають:
    </p>
    <p className="seo__list-item">
      відмову від прийому ліків (за можливості) за 2 тижня до візиту;
    </p>
    <p className="seo__list-item">
      відмову від статевих актів за 2 дня до візиту;
    </p>
    <p className="seo__list-item">
      відмову від спринцювань та інших втручань у мікрофлору піхви — за 2-3 доби
      до візиту;
    </p>
    <p className="seo__list-item">
      своєчасну гігієну перед візитом до лікаря — краще прийняти душ
      безпосередньо перед виходом з дому.
    </p>
    <h3 className="seo__subtitle mont-m">Оперативна гінекологія у Filada</h3>

    <p className="seo__description">
      Оперативна гінекологія — це особливий напрямок медицини. Він передбачає
      використання хірургічних методів для діагностики, лікування та
      профілактики гінекологічних хвороб. В клініці Filada гінеколог може
      проводити різні види оперативного втручання, зокрема:
    </p>
    <p className="seo__list-item">
      Діагностичні операції. Це вишкрібання стінок порожнини матки, біопсія
      ендометрію, гістероскопія та ін.
    </p>
    <p className="seo__list-item">
      Лікувальні операції. Це видалення новоутворень (поліпів, кіст, папілом
      тощо), вагінопластика, кольпорафія, конізація шийки матки та ін.
    </p>
    <p className="seo__description">
      Сучасне обладнання та висока кваліфікація лікарів дозволяють виконувати
      такі втручання з мінімальною травматизацією та швидким темпом відновлення.
    </p>
    <h3 className="seo__subtitle mont-m">Естетична гінекологія</h3>
    <p className="seo__description">
      Естетична гінекологія зосереджується на поліпшенні зовнішнього вигляду та
      функціональності статевих органів. Серед її основних задач:
    </p>
    <p className="seo__list-item">
      відновлення або покращення зовнішнього вигляду;
    </p>
    <p className="seo__list-item">покращення якості інтимного життя;</p>
    <p className="seo__list-item">
      корекція проблем, виниклих після пологів, травм або вікових змін;
    </p>
    <p className="seo__list-item">
      усунення психологічного дискомфорту через особливості статевих органів.
    </p>
    <p className="seo__description">
      Серед найпоширеніших процедур, що проводяться у клініці Filada:
    </p>
    <p className="seo__list-item">
      лабіопластика — зміна розміру або форми великих та малих статевих губ;
    </p>
    <p className="seo__list-item">відновлення дівочої пліви;</p>
    <p className="seo__list-item">
      аугментація (збільшення точки G) для підвищення чутливості;
    </p>
    <p className="seo__list-item">
      контурна пластика піхви за допомогою філерів для звуження або відновлення
      еластичності;
    </p>
    <p className="seo__list-item">
      PRP-терапія (Platelet-Rich Plasma) — передбачає ін`єкційне введення
      власної плазми пацієнта для стимуляції регенерації тканин, омолодження
      інтимної зони, лікування сухості, мікротріщин та рубців.
    </p>
    <p className="seo__description">
      Якщо вам потрібен кращий гінеколог в Чернігові, ласкаво просимо до Filada.
      Наші спеціалісти з гінекології допоможуть зберегти здоров`я, покращити
      якість життя, усунути різноманітні проблеми. Щоб записатися на
      консультацію гінеколога, запишіть свій номер телефону у форму на нашому
      сайті. Ми зателефонуємо вам у зручний для вас час. Повторна консультація
      гінеколога також відбувається за попереднім записом.
    </p>
  </div>
);

export const textCosmetology = (
  <div>
    <h1>Пластична хірургія в Чернігові</h1>
    <p>Это текст внутри переменной.</p>
  </div>
);

export const textDermatology = (
  <div>
    <h1>Пластична хірургія в Чернігові</h1>
    <p>Это текст внутри переменной.</p>
  </div>
);

export const textProctology = (
  <div>
    <h2 className="arial-r-seo">Проктологія в Чернігові</h2>
    <p className="seo__description">
      Проктологія в Чернігові є такою ж важливою та затребуваною, як і інші
      напрямки медицини. Різниця лише в тому, то питання, які входять до
      компетенції лікаря- проктолога є дуже делікатними. І багато пацієнтів
      навіть за наявності певних проблем відкладають візит до такого
      спеціаліста. Проте це може призвести до розвитку стійких хворобливих
      процесів. З плином часу лікування проктологічних захворювань відбувається
      набагато тяжче. Якщо на початку виникнення неприємних симптомів з ними
      можна було впоратися за допомогою ліків, то на пізніх стадіях може
      знадобитися хірургічне втручання. Консультація проктолога допоможе
      уникнути ускладнень.
    </p>
    <h3 className="seo__subtitle mont-m">
      Симптоми, за яких потрібно звернутися за консультацією до проктолога
    </h3>
    <p className="seo__description">
      Тривожні симптоми проктологічних хвороб, які вказують на необхідність
      консультації з лікарем, включають:
    </p>
    <p className="seo__list-item">
      біль, свербіж, дискомфорт в районі ануса, промежини чи прямої кишки;
    </p>
    <p className="seo__list-item">
      появу ущільнень та новоутворень в анальній області;
    </p>
    <p className="seo__list-item">
      появу у калових масах крові, слизу або гнійних виділень;
    </p>
    <p className="seo__list-item">регулярні закрепи або рідкий стілець;</p>
    <p className="seo__list-item">
      хронічні больові відчуття в області живота;
    </p>
    <p className="seo__list-item">
      відчуття неповного випорожнення кишківника навіть після вдалої дефекації;
    </p>
    <p className="seo__list-item">оманливі позиви до дефекації.</p>

    <p className="seo__description">
      Навіть один тривожний симптом — це важливий привід для консультації з
      лікарем.
    </p>

    <h3 className="seo__subtitle mont-m">Лікування геморою у клініці Filada</h3>
    <p className="seo__description">
      Одна з найпоширеніших причин для звернення до спеціаліста з проктології —
      це геморой. За такої хвороби відбувається варикозне розширення судин та
      утворення венозних вузлів. Вони локалізуються у нижній частині прямої
      кишки. На початку хвороби вона проявляється лише відчуттям тяжкості та
      печіння в області анального отвору. Також можлива поява невеликої
      кількості яскраво-червоної крові у кінці дефекації. За відсутності
      лікування вузли збільшуються у розмірах, можуть випадати назовні з
      заднього проходу, затискатися та некротизуватися.
    </p>
    <p className="seo__description">
      Проктологи МЦ “Filada” пропонують різні підходи до лікування геморою — від
      безопераційних (на ранніх стадіях) до малотравматичних (на пізніх
      стадіях). Зокрема в умовах нашої клініки проводиться малоінвазивне
      втручання HAL-RAR (HAL – гемороїдальне артеріальне лігування, RAR –
      ректо-анальна реконструкція). Серед переваг такого лікування:
    </p>
    <p className="seo__list-item">відсутність розрізів;</p>
    <p className="seo__list-item">
      відновлення природних функцій прямої кишки;
    </p>
    <p className="seo__list-item">усунення гемороїдальних вузлів;</p>
    <p className="seo__list-item">
      усунення першопричини виникнення геморою (лігування судин, постраждалих
      від варикозу);
    </p>
    <p className="seo__list-item">безболісність;</p>
    <p className="seo__list-item">мінімальний ризик ускладнень;</p>
    <p className="seo__list-item">швидке відновлення пацієнтів.</p>
    <p className="seo__description">
      Проктологічна операція HAL-RAR може проводитися під місцевою анестезією,
      наркозом або седацією. І наші лікарі роблять все, щоб пацієнти відчували
      себе комфортно.
    </p>
    <h3 className="seo__subtitle mont-m">
      Які ще проктологічні хвороби лікують у МЦ Filada?
    </h3>
    <p className="seo__description">
      У МЦ Filada діагностують і лікують не тільки геморой, а й інші
      проктологічні проблеми, зокрема:
    </p>

    <p className="seo__list-item">папіломи й кондиломи;</p>
    <p className="seo__list-item">інші доброякісні новоутворення;</p>
    <p className="seo__list-item">анальні тріщини;</p>
    <p className="seo__list-item">
      запальні процеси ануса та кишківника й навколишніх тканин
    </p>
    <p className="seo__list-item">параректальні свищі;</p>
    <p className="seo__list-item">випадання прямої кишки; </p>
    <h3 className="seo__subtitle mont-m">
      Чи треба якось готуватися до візиту до проктолога?{" "}
    </h3>
    <p className="seo__description">
      Головне завдання пацієнта під час підготовки до консультації проктолога —
      забезпечити чистоту прямої кишки та інших відділів кишківника. З цією
      метою варто:
    </p>
    <p className="seo__list-item">
      за дві доби до візиту відмовитися від споживання смажених та жирних страв,
      а також грибів;
    </p>
    <p className="seo__list-item">
      за 1-2 доби відмовтесь від споживання їжі, що може викликати надмірне
      газоутворення, зокрема бобових, також не пийте газовану воду;
    </p>
    <p className="seo__list-item">
      зробіть мікроклізму за кілька годин до прийому (якщо це порекомендували
      під час запису, якщо ж такої рекомендації не було, то мікроклізму робити
      не потрібно);
    </p>
    <p className="seo__list-item">прийміть душ безпосередньо перед візитом.</p>
    <p className="seo__description">
      Також не забудьте надягти зручний одяг, який легко та швидко знімається та
      надягається.
    </p>
    <h3 className="seo__subtitle mont-m">Як проходить візит до проктолога?</h3>
    <p className="seo__description">
      Огляд лікаря та діагностика проктологічних захворювань в медичному центрі
      Filada відбувається у комфортній для пацієнта атмосфері у кілька етапів:
    </p>
    <p className="seo__list-item">
      Бесіда. Спеціаліст цікавиться симптомами, способом життя, особливостями
      харчування, наявністю хронічних хвороб тощо.
    </p>
    <p className="seo__list-item">
      Візуальний огляд. Лікар проктолог оглядає анальну зону на наявність
      зовнішніх змін, зокрема тріщин, вузлів, запалень, новоутворень.
    </p>
    <p className="seo__list-item">
      Пальцеве ректальне обстеження. За допомогою пальців лікар обережно
      обстежує внутрішню поверхню прямої кишки, щоб оцінити стан тканин, вузлів
      або новоутворень.
    </p>
    <p className="seo__list-item">
      Інструментальні методи. Під час першого візиту пацієнту можуть проводити
      аноскопію або ректороманоскопію для детального огляду слизових оболонок
      прямої кишки.
    </p>
    <p className="seo__description">
      Первинний прийом проктолога займає близько пів години. Всі маніпуляції
      зазвичай є нетривалими та безболісними. Важливо розслабитися та
      дотримуватися вказівок лікаря. За потреби пацієнтові можуть призначати й
      інші діагностичні процедури, зокрема УЗД, біопсію, колоноскопію тощо.
    </p>
    <h3 className="seo__subtitle mont-m">Чому пацієнти обирають Filada?</h3>

    <p className="seo__description">
      Відділення проктології медичного центру Filada — це місце, де працюють
      висококваліфіковані спеціалісти.
    </p>
    <p className="seo__description">Серед інших переваг:</p>
    <p className="seo__list-item">комфортна атмосфера для пацієнтів;</p>
    <p className="seo__list-item">сучасне обладнання експертного класу;</p>
    <p className="seo__list-item">самий високий рівень медичних послуг;</p>
    <p className="seo__list-item">
      відповідальний підхід до сервісу обслуговування пацієнтів;
    </p>
    <p className="seo__list-item">
      відкритість та постійна комунікація з клієнтами;
    </p>
    <p className="seo__list-item">збереження конфіденційності вашого візиту.</p>
    <p className="seo__description">
      Якщо вам потрібен проктолог в Чернігові, ласкаво просимо до Filada. Ми
      зробимо все, щоб ваш візит був комфортним та ефективним. Записатися на
      консультацію дуже просто - впишіть свій номер у форму на сайті та чекайте
      нашого дзвінка у зручний для вас час.
    </p>
  </div>
);

export const textLor = (
  <div>
    <h1>Пластична хірургія в Чернігові</h1>
    <p>Это текст внутри переменной.</p>
  </div>
);

export const textDieteticsNutrition = (
  <div>
    <h2 className="arial-r-seo">Дієтологія/нутріціологія в Чернігові</h2>
    <h3 className="seo__subtitle mont-m">Чому важливо звернутися до нас?</h3>
    <p className="seo__list-item">Індивідуальний підхід: Програму харчування розробляють з
урахуванням ваших потреб, стилю життя та стану здоров`я.</p>
    <p className="seo__list-item">Нормалізація ваги: Допоможемо стати на правильний шлях до
зниження/збільшення ваги без стресу для організму.</p>
    <p className="seo__list-item">Поліпшення здоров`я: Сприяємо профілактиці та лікуванню різних
захворювань через правильне харчування.</p>
<h3 className="seo__subtitle mont-m">Напрямки роботи лікаря-дієтолога/нутриціолога медичного центру Філада</h3>
<h3 className="seo__subtitle mont-m">Лікування метаболічних порушень</h3>
<p className="seo__description">- Робота з пацієнтами з інсулінорезистентністю та метаболічним синдромом.</p>
<p className="seo__description">- Профілактика та корекція ожиріння та порушень вуглеводного обміну.</p>
<p className="seo__description">- Складання індивідуальних програм харчування для стабілізації рівня цукру в
крові.</p>
<h3 className="seo__subtitle mont-m">Персоналізоване харчування</h3>
<p className="seo__description">- Розробка дієт з урахуванням індивідуальних потреб, хронічних захворювань і
способу життя.</p>
<p className="seo__description">- Харчова корекція при ендокринних розладах і супутніх захворюваннях
шлунково-кишкового тракту.</p>
<h3 className="seo__subtitle mont-m">Підбір вітамінів і мінералів</h3>
<p className="seo__description">- Розробка індивідуальних програм прийому нутріцевтиків для підтримки
гормонального здоров’я.</p>
<p className="seo__description">- Визначення дефіцитів вітамінів (D, B12, магній, йод, залізо) та їх
компенсація.</p>
<p className="seo__description">- Рекомендації щодо прийому антиоксидантів, амінокислот і адаптогенів для
підвищення якості життя</p>
<p className="seo__description">Запишіться на консультацію до наших спеціалістів вже сьогодні і відкрийте для
себе новий рівень здоров`я та енергії!</p>

  </div>
);

export const textEndocrinology = (
  <div>
    <h2 className="arial-r-seo">Ендокринологія в Чернігові</h2>
    <h3 className="seo__subtitle mont-m">Більш детально про напрямки роботи ендокринолога в нашій клініці
Філада</h3>
<h3 className="seo__subtitle mont-m">Захворювання щитоподібної залози</h3>
<p className="seo__description">- Діагностика та лікування гіпотиреозу, гіпертиреозу, вузлових утворень, тиреоїдиту
Хашимото.</p>
<p className="seo__description">- Консервативна терапія з використанням сучасних стандартів та персоналізованих
підходів.</p>
<p className="seo__description">- Корекція харчування та нутрітивна підтримка для пацієнтів із захворюваннями щитовидної
залози.</p>
<h3 className="seo__subtitle mont-m">Гормональні порушення</h3>
<p className="seo__description">- Виявлення причин і лікування менструальних розладів, включаючи синдром полікістозних
яєчників (СПКЯ).</p>
<p className="seo__description">- Лікування гіпогонадизму та інших порушень гормонального балансу у чоловіків і жінок.</p>
<p className="seo__description">- Підтримка в період менопаузи: гормонозамісна терапія та природні підходи.</p>
<h3 className="seo__subtitle mont-m">Репродуктивне здоров’я</h3>
<p className="seo__description">- Консультації для пар, що планують вагітність, включаючи діагностику та лікування
ендокринного безпліддя.</p>
<p className="seo__description">- Підтримка жінок із ендокринними порушеннями під час вагітності.</p>
<p className="seo__description">- Моніторинг стану щитовидної залози у жінок під час вагітності.</p>
<h3 className="seo__subtitle mont-m">Цукровий діабет</h3>
<p className="seo__description">- Діагностика та лікування цукрового діабету 1 і 2 типу.</p>
<p className="seo__description">- Навчання пацієнтів контролю глікемії та управління ускладненнями діабету.</p>
<p className="seo__description">- Програми харчової підтримки для людей із діабетом.</p>
  </div>
);

export const textNeurology = (
  <div>
    <h2 className="arial-r-seo">Неврологія в Чернігові</h2>
    <h3 className="seo__subtitle mont-m">Якщо ви відмічаєте наступні симптоми, то вам необхідно
консультація фахового лікаря невролога:</h3>
<p className="seo__list-item">Швидка втомлюваність, загальна слабкість, запаморочення, головні болі</p>
<p className="seo__list-item">Слабкість в кінцівках, оніміння різних частин тіла</p>
<p className="seo__list-item">Відчуття повзання мурашок, поколювання</p>
<p className="seo__list-item">Епізоди втрати свідомості, непритомність</p>
<p className="seo__list-item">Судоми, спазми</p>
<p className="seo__list-item">Нервові тики, тремор</p>
<p className="seo__list-item">Двоїння в очах, мушки або пелена перед очима</p>
<p className="seo__list-item">Головокружіння, шум, свист у вухах</p>
<p className="seo__list-item">Розлади мови, пам`яті та уваги</p>
<p className="seo__list-item">Порушення сну</p>
<p className="seo__list-item">Больові синдроми (головний біль різної етіології, біль в кінцівках, спині)</p>
<p className="seo__list-item">Порушення чутливості</p>
<p className="seo__list-item">Втома та м’язова слабкість</p>
<p className="seo__list-item">Порушення координації, головокружіння, запаморочення</p>
<p className="seo__list-item">Порушення сну, пам’яті, когнітивних (розумових) функцій</p>
<p className="seo__list-item">Тривожно – депресивні розлади, емоційна лабільність, дратівливісь</p>
<h3 className="seo__subtitle mont-m">Які захворювання лікує невролог медичного центру Філада?</h3>
<p className="seo__list-item">Первинні головні болі : головний біль напруги, мігрень, кластерний головний біль та
більш рідкісні цефалгії.</p>
<p className="seo__list-item">Біль в нижній частині спини, корінцеві синдроми (радикулопатії), тунельні синдроми.</p>
<p className="seo__list-item">Рухові розлади (Хвороба Паркінсона, паркінсонізм, тремор, дистонії)</p>
<p className="seo__list-item">Епілепсія</p>
<p className="seo__list-item">Неврити, невралгії, нейропатії (корінцеві синдроми (радикулопатії), тунельні синдроми,
після травматичне ушкодження нервів)</p>
<p className="seo__list-item">Тривожни стан, порушення сну</p>
<p className="seo__list-item">Профілактика інсульту, ведення пацієнтів після інсульту (вторинна профілактика
(профілактика повторних) інсультів)
Загострення хронічних неврологічних хвороб</p>
<h3 className="seo__subtitle mont-m">Окремим напрямком діяльності лікаря - невролога в медичному
центрі Філада є лікування больових синдромів при мігрені
ботоксом</h3>
<p className="seo__description">Як працює ботокс при мігрені?</p>
<p className="seo__description">Ботулотоксин типу A (ботокс) блокує нервові закінчення, які передають больові імпульси,
і розслаблює м’язи. Це зменшує частоту та інтенсивність нападів мігрені. Метод офіційно
схвалений FDA (США) у 2010 році для профілактики хронічної мігрені</p>
<p className="seo__description">
  Лікування ботоксом активно використовується в США, Європі та інших країнах в
  комбінації з іншими методами лікування. Є частиною доказової медицини та
  рекомендоване міжнародними організаціями рекомендаціями, такими як{" "}
  <a className="seo__link" href="https://www.nice.org.uk/" target="_blank" rel="noopener noreferrer">
    NICE
  </a> (Велика Британія).
</p>

<p className="seo__list-item">Доказовість: Клінічні дослідження підтверджують, що ботокс значно знижує кількість
мігренозних днів на місяць, особливо у пацієнтів з хронічною формою</p>
<p className="seo__description">В медичному центрі Філада ми прагнемо застосовувати найсучасніші методи лікування
неврологічних захворювань</p>
  </div>
);
