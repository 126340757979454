export const reasonsArr = [
    {
        number: 1,
        title: "Висококваліфіковані фахівці",
        description: "Наші лікарі постійно вдосконалюються та підвищують свій професійний рівень.",
    },
    {
        number: 2,
        title: "Сучасне обладнання",
        description: "Використовуємо новітні технології та сучасне обладнання для вашої максимальної безпеки.",
    },
    {
        number: 3,
        title: "Індивідуальний підхід",
        description: "Кожен пацієнт отримує персоналізований план лікування та реабілітації.",
    },
    {
        number: 4,
        title: "Безпека та комфорт",
        description: "Дотримуємося високих стандартів гігієни та безпеки, забезпечуємо комфортні умови перебування.",
    },
    {
        number: 5,
        title: "Доступні ціни",
        description: "Оптимальне співвідношення ціни та якості на всі послуги.",
    },
    {
        number: 6,
        title: "Комфортний стаціонар",
        description: "У кожній палаті  є багатофункціональне ліжко, кнопка виклику медперсоналу, кондиціонер, система WiFi",
    },
]